import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/index.md"

import legalPaper from "../../images/legal/legal-page.svg"

export default function LegalPage() {
  return (
    <LegalLayout
      heading="Policies, Terms, and Legal Stuff"
      source={source}
      featureImg={legalPaper}
      title="Policies, terms, and legal stuff"
      withoutBack
    />
  )
}
